import React, { useState, useEffect } from "react";

import { Box, Modal, TextField, Button, Typography, MenuItem } from "@mui/material";

import classes from "./FixedPricesModal.module.css";

import { useDispatch } from "react-redux";

import { getFixedPrices, setFixedPrice, deleteFixedPrice } from "../../../store/actions/fixedPrices";

const FixedPricesModal = (params) => {
  const dispatch = useDispatch();
  const handleClose = () => params.setIsModalOpen(false);
  const [fixedPriceForm, setFixedPriceForm] = useState();

  useEffect(() => {
    let form;
    if (params.fixedPrice) {
      form = JSON.parse(JSON.stringify(params.fixedPrice));
      form.price = form.price ? form.price : "";
      form.stock = form.stock ? form.stock : "";
      form.active = form.active ? form.active : "";
    }
    setFixedPriceForm(form);
  }, [params.fixedPrice]);

  const handleFormChange = (event) => {
    const target = event.target.name;
    let newForm = JSON.parse(JSON.stringify(fixedPriceForm));
    if (typeof newForm[target] === "undefined") {
      return console.log(target + " not found!");
    }
    newForm[target] = event.target.value;

    setFixedPriceForm(newForm);
  };

  const handleAccept = async (e) => {
    let saveResult;
    if (params.mode === "edit") {
      saveResult = await dispatch(setFixedPrice(fixedPriceForm));
    } else if (params.mode === "delete") {
      saveResult = await dispatch(deleteFixedPrice(fixedPriceForm));
    }
    if (saveResult) {
      await dispatch(getFixedPrices());
      params.setIsModalOpen(false);
    }
  };

  return (
    <div>
      <Modal
        open={params.isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.box}>
          <Typography id="fixed-price-modal-title" variant="h6" component="h2" sx={{ marginTop: "10px" }}>
            {fixedPriceForm?.partNumber}
          </Typography>
          <div className={classes.bodySection}>
            {params.mode === "edit" && (
              <div className={classes.inputSection}>
                <TextField
                  id="fixed_price_modal_price_input"
                  label="Cena"
                  type="number"
                  name="price"
                  value={fixedPriceForm?.price}
                  size="small"
                  onChange={handleFormChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="dense"
                />
                <TextField
                  id="fixed_price_modal_stock_input"
                  label="Magazyn"
                  type="number"
                  name="stock"
                  value={fixedPriceForm?.stock}
                  size="small"
                  onChange={handleFormChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="dense"
                />
                <TextField
                  id="fixed_prices_modal_status_select"
                  className={classes.textField}
                  label="Status"
                  select
                  value={fixedPriceForm?.status}
                  name="status"
                  onChange={handleFormChange}
                  size="small"
                  sx={{ width: "220px", marginTop: "10px" }}
                >
                  <MenuItem value="">
                    <em>Nie zmieniaj</em>
                  </MenuItem>
                  <MenuItem value="Nieaktywny">Nieaktywny</MenuItem>
                  <MenuItem value="Brak">Brak</MenuItem>
                  <MenuItem value="Na zamówienie">Na zamówienie</MenuItem>
                  <MenuItem value="Dostępny">Dostępny</MenuItem>
                  <MenuItem value="Brak/Na zamówienie">Brak/Na zamówienie</MenuItem>
                </TextField>
                <TextField
                  id="fixed_prices_modal_active_select"
                  className={classes.textField}
                  label="Aktywny"
                  select
                  value={fixedPriceForm?.active}
                  name="active"
                  onChange={handleFormChange}
                  size="small"
                  sx={{ width: "220px", marginTop: "15px" }}
                >
                  <MenuItem value="">
                    <em>Nie zmieniaj</em>
                  </MenuItem>
                  <MenuItem value="0">0</MenuItem>
                  <MenuItem value="1">1</MenuItem>
                </TextField>
              </div>
            )}
            {params.mode === "delete" && <p>Czy chcesz usunąć tą cenę?</p>}
          </div>
          <div className={classes.centerRow}>
            <Button color="warning" onClick={handleClose} sx={{ marginRight: "5px" }}>
              Anuluj
            </Button>
            <Button color="success" variant="outlined" onClick={handleAccept} sx={{ marginLeft: "5px" }}>
              Akceptuj
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FixedPricesModal;
